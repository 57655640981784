import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StyledCarCard, Wrapper } from "./style";
import { Scroller } from "./../Scroller";
import styled from "styled-components";
import ReservationBox from "@common/ReservationBox";
import { Modal } from "@material-ui/core";

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

const StyledReservationBox = styled(ReservationBox)`
  max-width: 450px;
  height: auto;
`;

const CarsCarousel = ({ onChoose, rentalState }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            segments {
              name
              dayPrices
              deposit
              insurance
              cars {
                id
                name
                fuelType
                transmission
                engineEfficiency
                airConditioning
                fuelTypeFull
                seats
                doors
                productionYear
                engineVolume
                enginePower
                trunkCapacity
                range
                equipment
                description
              }
            }
          }
        }
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  );
  const [reservationPopup, setReservationPopup] = useState(null);

  const closeReservationPopup = () => setReservationPopup(null);

  const segments = data.site.siteMetadata.segments;
  const cars = [];
  segments.forEach((segment) => {
    segment.cars.forEach((car) => {
      const foundNode = data.allImageSharp.edges.find((element) => {
        // Match string after final slash
        return element.node.fluid.src.split("/").pop().includes(car.id);
      });
      const img = foundNode ? foundNode.node.fluid : null;
      cars.push(
        <StyledCarCard
          key={car.id}
          segment={segment}
          car={car}
          img={img}
          rentalState={rentalState}
          hidePrices
          onChoose={() => {
            setReservationPopup({
              car,
              segment,
            });
          }}
        />
      );
    });
  });
  return (
    <Wrapper>
      {reservationPopup ? (
        <StyledModal open={true} onClose={closeReservationPopup}>
          <StyledReservationBox {...reservationPopup} />
        </StyledModal>
      ) : null}
      <Scroller auto={4000}>{cars}</Scroller>
    </Wrapper>
  );
};
export default CarsCarousel;
