import React from "react";
import Collapsible from "react-collapsible";

import { Wrapper, WrapperCollapsible } from "./style";

const FaqItem = ({ title, children, collapsible }) => {
  if (collapsible) {
    return (
      <WrapperCollapsible>
        <Collapsible
          className="faq"
          openedClassName="faq active"
          triggerClassName="faq-title"
          triggerOpenedClassName="faq-title active"
          triggerTagName="button"
          contentInnerClassName="faq-content"
          trigger={title}
          transitionTime={300}
          easing="ease-out"
        >
          <div className="content">{children}</div>
        </Collapsible>
      </WrapperCollapsible>
    );
  }
  return (
    <Wrapper>
      <h3>{title}</h3>
      <div className="content">{children}</div>
    </Wrapper>
  );
};

export default FaqItem;
