import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ArrowBack from "@material-ui/icons/ChevronLeftRounded";
import ArrowForward from "@material-ui/icons/ChevronRightRounded";
import { useScroll, useInterval, useScrolling } from "react-use";

const PrevNextButton = styled.button`
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  background: black;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    display: none;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    color: white;
  }

  &::before {
    content: " ";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
  }

  /* &:hover,
  &:focus,
  &:active {
    border: solid 2px ${(props) => props.theme.color.primary.main};
    outline: none;
  } */
`;

const PrevButton = styled((props) => (
  <PrevNextButton {...props}>
    <ArrowBack />
  </PrevNextButton>
))`
  left: 15px;
`;

const NextButton = styled((props) => (
  <PrevNextButton {...props}>
    <ArrowForward />
  </PrevNextButton>
))`
  right: 15px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  /* ${PrevButton}, ${NextButton} {
    display: none;
  }
  &:hover ${PrevButton}, &:hover ${NextButton} {
    display: block;
  } */
`;

const Rail = styled(
  forwardRef(({ numItems, ...props }, ref) => (
    <div data-rail ref={ref} {...props} />
  ))
)`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${(props) => props.numItems}, 1fr);
  grid-gap: 1rem;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  & > a,
  & > div,
  & > button {
    flex-shrink: 0;
    display: block;
  }
`;

const VISIBILITY_MARGIN = 10;

//t = current time
//b = start value
//c = change in value
//d = duration
const easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

function scrollTo(element, to, duration) {
  var start = element.scrollLeft,
    change = to - start,
    currentTime = 0;

  let lastTime = performance.now();

  var animateScroll = function (time) {
    currentTime += time - lastTime;
    lastTime = time;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollLeft = to;
    }
  };
  requestAnimationFrame(animateScroll);
}

export const Scroller = ({ children, auto, ...rest }) => {
  const scrollRef = useRef(null);
  const { x, y } = useScroll(scrollRef);
  const [lastInteraction, setLastInteraction] = useState(0);
  const [width, setWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const scrolling = useScrolling(scrollRef);

  const findNthChild = (n) => {
    if (scrollRef && scrollRef.current) {
      return scrollRef.current.children[n];
    }
    return null;
  };

  const findClosestElement = (x) => {
    if (scrollRef && scrollRef.current) {
      let closest = null;
      let index = 0;
      let currIndex = 0;
      for (const item of scrollRef.current.children) {
        if (
          !closest ||
          Math.abs(x - item.offsetLeft) < Math.abs(x - closest.offsetLeft)
        ) {
          closest = item;
          index = currIndex;
        }
        currIndex += 1;
      }

      return {
        item: closest,
        index,
      };
    }
    return null;
  };

  const scrollLeft = (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (scrollRef && scrollRef.current) {
      scrollTo(scrollRef.current, Math.max(0, x - width), 300);
    }
  };

  const scrollItemsLeft = (ev, n = 1) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (scrollRef && scrollRef.current) {
      const current = findClosestElement(x);
      if (!current) {
        return;
      }
      const targetIndex = Math.max(0, current.index - n);
      const targetItem = findNthChild(targetIndex);
      if (!targetItem) {
        return;
      }
      scrollTo(scrollRef.current, targetItem.offsetLeft, 300);
    }
  };

  const scrollItemsRight = (ev, n = 1) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (scrollRef && scrollRef.current) {
      const current = findClosestElement(x);
      if (!current) {
        return;
      }
      if (x + width >= scrollWidth) {
        // Reached the end, scroll to beginning
        scrollTo(scrollRef.current, 0, 300);
        return;
      }
      const targetIndex = Math.min(children.length - 1, current.index + n);
      const targetItem = findNthChild(targetIndex);
      if (!targetItem) {
        return;
      }
      scrollTo(scrollRef.current, targetItem.offsetLeft, 300);
    }
  };

  const scrollRight = (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (scrollRef && scrollRef.current) {
      if (x + width >= scrollWidth) {
        // Reached the end, scroll to beginning
        scrollTo(scrollRef.current, 0, 300);
      } else {
        scrollTo(
          scrollRef.current,
          Math.min(scrollWidth - width, x + width),
          300
        );
      }
    }
  };

  useEffect(() => {
    setWidth(
      scrollRef && scrollRef.current ? scrollRef.current.clientWidth : 0
    );
    setScrollWidth(
      scrollRef && scrollRef.current ? scrollRef.current.scrollWidth : 0
    );
  }, [x, scrollRef]);

  useEffect(() => {
    setLastInteraction(performance.now());
  }, [scrolling]);

  useInterval(
    () => {
      if (performance.now() - lastInteraction > auto) {
        scrollItemsRight();
      }
    },
    auto ? auto : null
  );

  if (!children || children.length < 1) {
    return null;
  }

  return (
    <Wrapper {...rest}>
      <Rail numItems={children.length} ref={scrollRef}>
        {children}
      </Rail>
      {x > VISIBILITY_MARGIN ? (
        <PrevButton
          onClick={() => {
            setLastInteraction(performance.now());
            scrollItemsLeft();
          }}
        />
      ) : null}
      {x < Math.max(scrollWidth - width - VISIBILITY_MARGIN) ? (
        <NextButton
          onClick={() => {
            setLastInteraction(performance.now());
            scrollItemsRight();
          }}
        />
      ) : null}
    </Wrapper>
  );
};
