import React from "react";
import styled from "styled-components";
import { Car } from "styled-icons/fa-solid";
import { Calendar } from "styled-icons/boxicons-regular";
import { Check } from "styled-icons/fa-solid";
import { Happy2 } from "styled-icons/icomoon";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";

import { Container } from "@components/global";

const Features = () => (
  <Bg>
    <Container>
      <Wrapper>
        <Feature>
          <Calendar />
          <Desc>Wybierz okres najmu</Desc>
        </Feature>
        <StepArrow step={1} />
        <Feature>
          <Car />
          <Desc>Dobierz odpowiednie auto</Desc>
        </Feature>
        <StepArrow step={2} />
        <Feature>
          <Check />
          <Desc>Wypełnij formularz kontaktowy</Desc>
        </Feature>
        <StepArrow step={3} />
        <Feature>
          <Happy2 />
          <Desc>Ciesz się wynajętym samochodem</Desc>
        </Feature>
      </Wrapper>
    </Container>
  </Bg>
);

const StepArrow = styled(({ step, ...rest }) => (
  <ArrowRightAltRoundedIcon {...rest} />
))`
  width: 40px;
  height: 30px;
  display: block;
  margin: 0 auto;
  fill: #ddd;
  top: 68%;
  ${(props) => {
    if (props.step === 1) {
      return `
          left: 22%;
          transform: translateY(-50%) scale(2);
          margin-top: -30px;
        `;
    }
    if (props.step === 2) {
      return `
      left: 48%;
      transform: translateY(-50%) scale(2);
      margin-top: -30px;
        `;
    }
    if (props.step === 3) {
      return `
      left: 72.5%;
      transform: translateY(-50%) scale(2);
      margin-top: -30px;
        `;
    }
    return ``;
  }}
  position: absolute;
  display: none;
  @media (min-width: ${(props) => props.theme.screen.md}) {
    display block;
  }
`;

const Feature = styled.section`
  padding: 8px;
  position: relative;
  text-align: center;

  svg {
    width: 40px;
    height: 30px;
    display: block;
    margin: 0 auto;
    color: #fff;
  }
`;

const Desc = styled.p`
  display: block;
  margin: 5px 0;
  margin-bottom: 0;
  ${(props) => props.theme.font_size.small};
  color: #fff;
`;

const Bg = styled.div`
  box-shadow: 0 2px 16px 4px rgba(0, 0, 0, 0.16);
  z-index: 1;
  position: relative;
  background-color: ${(props) => props.theme.color.primary.regular};
`;

const Wrapper = styled.section`
  margin: 0;
  position: relative;
  @media (min-width: ${(props) => props.theme.screen.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: stretch;
    padding: 8px 0;
  }
`;
export default Features;
