import styled from "styled-components";
import Img from "gatsby-image";

import CarCard from "@common/CarCard";

export const StyledCarCard = styled(CarCard)`
  min-width: 100vw;
  box-shadow: none;
  margin: 1.5rem 0;
  @media (min-width: 350px) {
    min-width: auto;
    width: 350px;
  }
`;

export const Wrapper = styled.div``;

export const Segment = styled.div``;
export const SegmentHeader = styled.h2`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.large};
  margin: 1rem 0;
`;
export const SegmentCars = styled.div`
  display: grid;
  grid-gap: 32px;

  @media (min-width: 1080px) {
    grid-auto-flow: column;
    grid-auto-columns: 500px;
  }
`;
